const Sentry = require('@sentry/nextjs');

const localhost = 'localhost';

const getSentryEnv = (environment) => environment || localhost;

const getSentryEnabled = (environment) => getSentryEnv(environment) !== localhost;

// Sentry automatically sets the event type to "default". This is because captureMessage()
const captureSentryMessage = (message, sentryPayload = {}) => (
  Sentry.captureMessage(message, {
    level: 'info',
    extra: sentryPayload,
    tags: { no_replay: true }, // Custom tag to exclude from replays
    contexts: { replay: { shouldExclude: true } }, // Helps exclude from replays
  })
);

const captureSentryException = (error, sentryPayload = {}) => (
  Sentry.captureException(error, {
    extra: sentryPayload,
  })
);

module.exports = {
  getSentryEnv,
  getSentryEnabled,
  captureSentryMessage,
  captureSentryException,
};