/* eslint-disable no-case-declarations */

import get from 'lodash/get';
import { actionTypes, uppyFileAdded, uppyFileRemoved, uppyUploadProgress, uppyUploadStarted, uppyUploadPausedOrResumed, uppyUploadCompleted } from '../actions/uppy';

const uppyMiddleware = ({ createUppyClient, processAdminClient }) => {
  const instances = {};

  return store => next => action => {
    switch (action.type) {
      case actionTypes.UPPY_INIT:
        if (instances[action.payload.source]) return next(action);

        const { instance } = createUppyClient({
          autoProceed: action.payload.autoProceed,
          multiple: action.payload.multiple,
          onCreateMultipartUpload: (file) => store.dispatch(processAdminClient.createMultipartUpload(action.payload.bucket, file, action.payload.metadata)),
          onListParts: (uploadId, key) => store.dispatch(processAdminClient.listParts(action.payload.bucket, uploadId, key)),
          onPrepareUploadPart: (uploadId, number, key) => store.dispatch(processAdminClient.prepareUploadPart(action.payload.bucket, uploadId, number, key)),
          onAbortMultipartUpload: (uploadId, key) => store.dispatch(processAdminClient.abortMultipartUpload(action.payload.bucket, uploadId, key)),
          onCompleteMultipartUpload: (uploadId, key, parts) => store.dispatch(processAdminClient.completeMultipartUpload(action.payload.bucket, uploadId, key, parts)),
          onFileAdded: (file) => store.dispatch(uppyFileAdded({ source: action.payload.source, file })),
          onFileRemoved: (file) => store.dispatch(uppyFileRemoved({ source: action.payload.source, file })),
          onUploadProgress: (fileId, progress) => {
            const onUploadProgressFn = get(action, 'payload.onUploadProgress.fn');
            const onUploadProgressProps = get(action, 'payload.onUploadProgress.props');
            store.dispatch(uppyUploadProgress({ source: action.payload.source, fileId, progress: { bytesUploaded: progress.bytesUploaded, bytesTotal: progress.bytesTotal } }));
            if (typeof onUploadProgressFn === 'function') store.dispatch(onUploadProgressFn(onUploadProgressProps));
          },
          onUploadStarted: (fileIDs) => fileIDs.forEach(fileId => store.dispatch(uppyUploadStarted({ source: action.payload.source, fileId }))),

          onUploadPaused: (fileId, isPaused) => store.dispatch(uppyUploadPausedOrResumed({ source: action.payload.source, fileId, isPaused })),
          onUploadComplete: (successful, failed) => {
            const onUploadCompleteFn = get(action, 'payload.onUploadComplete.fn');
            const onUploadCompleteProps = get(action, 'payload.onUploadComplete.props');

            store.dispatch(uppyUploadCompleted({
              source: action.payload.source, successful, failed,
            }));
            if (typeof onUploadCompleteFn === 'function') {
              store.dispatch(onUploadCompleteFn({
                ...onUploadCompleteProps,
                output: { fileList: successful.map(f => f.meta.fileName) } }));
            }
          },
        });

        instances[action.payload.source] = instance;

        return next(action);
      case actionTypes.UPPY_ADD_FILES:
        const source = get(action, 'payload.source');
        const uppy = instances[source];
        if (!uppy) return next(action);

        const folder = get(action, 'payload.folder');
        const files = get(action, 'payload.files', []);
        const state = store.getState();
        const selectedFiles = get(state, ['uppy', 'uploader', action.payload.source, 'files'], []).map(f => f.meta.fileName);

        files.forEach(file => {
          const name = folder ? `${folder}/${file.data.name}` : file.data.name;

          if (selectedFiles.includes(file.data.name)) return console.warn(`Ignoring already selected ${file.data.name} file`); // Ignore already selected files to avoid Uppy errors

          uppy.addFile({
            name,
            type: file.data.type,
            data: file.data,
            meta: {
              fileName: file.data.name,
              error: file.error,
            },
          });
        });

        return next(action);
      case actionTypes.UPPY_REMOVE_FILE:
        if (!instances[action.payload.source]) return next(action);

        instances[action.payload.source].removeFile(action.payload.fileId);

        return next(action);
      case actionTypes.UPPY_START_UPLOAD:
        if (!instances[action.payload.source]) return next(action);

        instances[action.payload.source].upload();

        return next(action);
      case actionTypes.UPPY_PAUSE_RESUME_FILE:
        if (!instances[action.payload.source]) return next(action);

        instances[action.payload.source].pauseResume(action.payload.fileId);

        return next(action);
      default:
        return next(action);
    }
  };
};

export default uppyMiddleware;
